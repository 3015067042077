<template>
  <div class="information">
    <div class="page-title">
      {{ translate(pageTitle, pageTitle) }}
    </div>

    <b-jumbotron
      class="page-header contained"
      :header="translate(jumbotron.header, jumbotron.headerDefault)"
      :lead="translate(jumbotron.lead, jumbotron.leadDefault)"
      />

    <section class="sections">
      <section
        class="information-section">
        <article
          class="information-article contained"
          v-for="(method, methodIndex) in methods"
          :key="methodIndex"
          :class="{'selected' : selectedMethod == method.methodId}"
          @click="goToMethod(method)">
          <div
            class="information-article-header">
            <div class="label">
              {{ translate(`method-${method.methodId}-contentTitle`, method.contentTitle) }}
            </div>

            <div class="article-header-button-wrapper">
              <b-button
                class="round"
                variant="outline-info">
                <font-awesome-icon
                  :icon="['fas', 'arrow-right']" />
              </b-button>
            </div>
          </div>
        </article>

        <article
          class="information-article contained featured"
          :class="{'selected' : selectedMethod == 'compare'}"
          @click="goToCompareOptions()">
          <div
            class="information-article-header">
            <div class="label">
              {{ translate(compareOptions.label, compareOptions.labelDefault) }}
            </div>

            <div class="article-header-button-wrapper">
              <b-button
                class="round"
                variant="outline-info">
                <font-awesome-icon
                  :icon="compareOptions.icon" />
              </b-button>
            </div>
          </div>
        </article>
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { snakeCase } from 'lodash';

export default {
  name: 'Methods',

  data: () => ({
    loading: false,
    pageTitle: 'methods',
    jumbotron: {
      header: 'availableMethodsTitle',
      headerDefault: 'Available methods to prevent pregnancy',
      lead: 'availableMethodsText',
      leadDefault: 'Methods that will prevent pregnancy for some time (temporary) are shown in light blue and methods that will stop pregnancy for lifetime (permanent) are shown in dark blue. You can also compare methods via the link at the bottom of the page',
    },
    compareOptions: {
      label: 'unsureRightMethod',
      labelDefault: 'Unsure about the right method for you? Click here to compare options.',
      link: '/compare-select',
      featured: true,
      icon: ['fas', 'arrow-right'],
    },
    selectedMethod: null,
  }),

  computed: {
    ...mapGetters('method', [
      'methods',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),
  },

  methods: {
    ...mapActions('method', [
      'resetCompareMethods',
    ]),
    methodLink(method) {
      return { name: 'MethodInformation', params: { methodId: method.methodId, methodName: snakeCase(method.name) } };
    },

    async goToMethod(method) {
      this.loading = true;
      this.selectedMethod = method.methodId;
      await this.removeSelectedMethod();
      this.$router.push(this.methodLink(method));
      this.loading = false;
    },
    removeSelectedMethod() {
      return new Promise((resolve) => setTimeout(function () {
        this.selectedMethod = null;
        resolve();
      }.bind(this), 100));
    },

    async goToCompareOptions() {
      this.selectedMethod = 'compare';
      await this.removeSelectedMethod();
      await this.resetCompareMethods();
      this.$router.push(this.compareOptions.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.questions {
  margin: 1.5rem 0;
}
</style>
